'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fad';
var iconName = 'bus';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'f207';
// eslint-disable-next-line no-unused-vars
var svgPathData = [
  'M352 448v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32zM64 480a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32H64zm64-192h256a32 32 0 0 0 32-32V128a32 32 0 0 0-32-32H128a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32z',
  'M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h8v160a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V256h8a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zm-392 0a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32v128a32 32 0 0 1-32 32H128a32 32 0 0 1-32-32zm16 272a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm288 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32z',
];
// var svgPathDataX = [
//   {
//     opacity: 0.4,
//     path: svgPathDataOriginal[0],
//   },
//   {
//     path: svgPathDataOriginal[1],
//   },
// ];
exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faBus = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
