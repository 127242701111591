var prefix = "fad";
var faArrowAltDown = {
  prefix: 'fad',
  iconName: 'arrow-alt-down',
  icon: [448, 512, [], "f354", ["M288,56V256H159.88V56a24,24,0,0,1,24-24H264A24,24,0,0,1,288,56Z", "M408.93,297,241,473a24.09,24.09,0,0,1-34,0L39.07,297c-15.11-15.09-4.4-41,17-41H391.93C413.35,256,424,281.76,408.93,297Z"]]
};
var faArrowAltUp = {
  prefix: 'fad',
  iconName: 'arrow-alt-up',
  icon: [448, 512, [], "f357", ["M160,456V256H288.11V456a24,24,0,0,1-24,24H184A24,24,0,0,1,160,456Z", "M39.05,215,207,39a24.08,24.08,0,0,1,34,0L408.92,215c15.11,15.09,4.4,41-17,41H56.05C34.63,256,24,230.3,39.05,215Z"]]
};
var faBus = {
  prefix: 'fad',
  iconName: 'bus',
  icon: [512, 512, [], "f207", ["M352 448v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32zM64 480a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32H64zm64-192h256a32 32 0 0 0 32-32V128a32 32 0 0 0-32-32H128a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32z", "M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h8v160a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V256h8a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zm-392 0a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32v128a32 32 0 0 1-32 32H128a32 32 0 0 1-32-32zm16 272a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm288 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"]]
};
var faBusAlt = {
  prefix: 'fad',
  iconName: 'bus-alt',
  icon: [512, 512, [], "f55e", ["M96 160v96a32 32 0 0 0 32 32h112V128H128a32 32 0 0 0-32 32zm320 96v-96a32 32 0 0 0-32-32H272v160h112a32 32 0 0 0 32-32zM64 480a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32H64zm288-32v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32z", "M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h8v160a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V256h8a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zM112 400a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm128-112H128a32 32 0 0 1-32-32v-96a32 32 0 0 1 32-32h112zM168 96a8 8 0 0 1-8-8V72a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H168zm104 32h112a32 32 0 0 1 32 32v96a32 32 0 0 1-32 32H272zm128 272a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"]]
};
var faCar = {
  prefix: 'fad',
  iconName: 'car',
  icon: [512, 512, [], "f1b9", ["M319.5 128a48 48 0 0 1 44.57 30.17L384 208H128l19.93-49.83A48 48 0 0 1 192.5 128zM80 384a63.82 63.82 0 0 1-47.57-21.2A31.82 31.82 0 0 0 32 368v48a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32zm352 0h-48v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-48a31.82 31.82 0 0 0-.43-5.2A63.82 63.82 0 0 1 432 384z", "M500 176h-59.88l-16.64-41.6A111.43 111.43 0 0 0 319.5 64h-127a111.47 111.47 0 0 0-104 70.4L71.87 176H12A12 12 0 0 0 .37 190.91l6 24A12 12 0 0 0 18 224h20.08A63.55 63.55 0 0 0 16 272v48a64 64 0 0 0 64 64h352a64 64 0 0 0 64-64v-48a63.58 63.58 0 0 0-22.07-48H494a12 12 0 0 0 11.64-9.09l6-24A12 12 0 0 0 500 176zm-352.07-17.83A48 48 0 0 1 192.5 128h127a48 48 0 0 1 44.57 30.17L384 208H128zM96 256c19.2 0 48 28.71 48 47.85s-28.8 15.95-48 15.95-32-12.8-32-31.9S76.8 256 96 256zm272 47.85c0-19.14 28.8-47.85 48-47.85s32 12.76 32 31.9-12.8 31.9-32 31.9-48 3.2-48-15.95z"]]
};
var faCarSide = {
  prefix: 'fad',
  iconName: 'car-side',
  icon: [640, 512, [], "f5e4", ["M144 320a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm352 0a80 80 0 1 0 80 80 80 80 0 0 0-80-80zM369.24 96H280v96h166zm-252.31 96H232V96h-76.67z", "M16 384h17.14a112 112 0 0 1 221.72 0h130.28a112 112 0 0 1 221.72 0H624a16 16 0 0 0 16-16v-80a96 96 0 0 0-96-96h-16L419.22 56a64 64 0 0 0-50-24H155.33a64 64 0 0 0-59.42 40.23L48 194.26A63.85 63.85 0 0 0 0 256v112a16 16 0 0 0 16 16zM280 96h89.24L446 192H280zm-124.67 0H232v96H116.93z"]]
};
var faCircle = {
  prefix: 'fad',
  iconName: 'circle',
  icon: [512, 512, [], "f111", ["M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 424c-97.06 0-176-79-176-176S158.94 80 256 80s176 79 176 176-78.94 176-176 176z", "M256 432c-97.06 0-176-79-176-176S158.94 80 256 80s176 79 176 176-78.94 176-176 176z"]]
};
var faClock = {
  prefix: 'fad',
  iconName: 'clock',
  icon: [512, 512, [], "f017", ["M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z", "M348.49,321h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"]]
};
var faEdit = {
  prefix: 'fad',
  iconName: 'edit',
  icon: [576, 512, [], "f044", ["M564.6 60.2l-48.8-48.8a39.11 39.11 0 0 0-55.2 0l-35.4 35.4a9.78 9.78 0 0 0 0 13.8l90.2 90.2a9.78 9.78 0 0 0 13.8 0l35.4-35.4a39.11 39.11 0 0 0 0-55.2zM427.5 297.6l-40 40a12.3 12.3 0 0 0-3.5 8.5v101.8H64v-320h229.8a12.3 12.3 0 0 0 8.5-3.5l40-40a12 12 0 0 0-8.5-20.5H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V306.1a12 12 0 0 0-20.5-8.5z", "M492.8 173.3a9.78 9.78 0 0 1 0 13.8L274.4 405.5l-92.8 10.3a19.45 19.45 0 0 1-21.5-21.5l10.3-92.8L388.8 83.1a9.78 9.78 0 0 1 13.8 0z"]]
};
var faFileAlt = {
  prefix: 'fad',
  iconName: 'file-alt',
  icon: [384, 512, [], "f15c", ["M384 128H272a16 16 0 0 1-16-16V0H24A23.94 23.94 0 0 0 0 23.88V488a23.94 23.94 0 0 0 23.88 24H360a23.94 23.94 0 0 0 24-23.88V128zm-96 244a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12zm0-64a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12zm0-64a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12z", "M377 105L279.1 7a24 24 0 0 0-17-7H256v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM276 352H108a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zm0-64H108a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zm0-64H108a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12z"]]
};
var faInfoCircle = {
  prefix: 'fad',
  iconName: 'info-circle',
  icon: [512, 512, [], "f05a", ["M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 110a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z", "M256 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z"]]
};
var faInfoSquare = {
  prefix: 'fad',
  iconName: 'info-square',
  icon: [448, 512, [], "f30f", ["M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-176 86a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z", "M224 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z"]]
};
var faListAlt = {
  prefix: 'fad',
  iconName: 'list-alt',
  icon: [512, 512, [], "f022", ["M464 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM128 392a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm0-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm0-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm288 168a12 12 0 0 1-12 12H204a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h200a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12H204a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h200a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12H204a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h200a12 12 0 0 1 12 12z", "M128 200a40 40 0 1 0-40-40 40 40 0 0 0 40 40zm0 16a40 40 0 1 0 40 40 40 40 0 0 0-40-40zm0 96a40 40 0 1 0 40 40 40 40 0 0 0-40-40z"]]
};
var faLongArrowRight = {
  prefix: 'fad',
  iconName: 'long-arrow-right',
  icon: [448, 512, [], "f178", ["M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z", "M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z"]]
};
var faPrint = {
  prefix: 'fad',
  iconName: 'print',
  icon: [512, 512, [], "f02f", ["M64 480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32v-96H64zM368 96a16 16 0 0 1-16-16V0H96a32 32 0 0 0-32 32v192h384V96z", "M368 96h80v-4.58a17.92 17.92 0 0 0-5.25-12.67l-73.43-73.5A18 18 0 0 0 356.57 0H352v80a16 16 0 0 0 16 16zm80 96v32H64v-32a64 64 0 0 0-64 64v112a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16V256a64 64 0 0 0-64-64zm-16 136a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"]]
};
var faSack = {
  prefix: 'fad',
  iconName: 'sack',
  icon: [512, 512, [], "f81c", ["M192 96h128l47.4-71.12A16 16 0 0 0 354.09 0H157.94a16 16 0 0 0-13.31 24.88zm128 32H192C-10.38 243.4.09 396.64.09 416c0 53 49.11 96 109.68 96h292.48c60.58 0 109.68-43 109.68-96 0-19 9.35-173.24-191.93-288z", "M337 104v16a8 8 0 0 1-8 8H183a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h146a8 8 0 0 1 8 8z"]]
};
var faSlash = {
  prefix: 'fad',
  iconName: 'slash',
  icon: [640, 512, [], "f715", ["M636.63 480.55L617 505.82a16 16 0 0 1-22.46 2.81L6.18 53.9a16 16 0 0 1-2.81-22.45L23 6.18a16 16 0 0 1 22.47-2.81L633.82 458.1a16 16 0 0 1 2.81 22.45z", ""]]
};
var faSpinner = {
  prefix: 'fad',
  iconName: 'spinner',
  icon: [512, 512, [], "f110", ["M108.92 355.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 416a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm208-208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm-60.92 147.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-198.16a48 48 0 1 0-48-48 48 48 0 0 0 48 48z", "M108.92 60.92a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM48 208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 0a48 48 0 1 0 48 48 48 48 0 0 0-48-48z"]]
};
var faSpinnerThird = {
  prefix: 'fad',
  iconName: 'spinner-third',
  icon: [512, 512, [], "f3f4", ["M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z", "M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z"]]
};
var faThumbsDown = {
  prefix: 'fad',
  iconName: 'thumbs-down',
  icon: [512, 512, [], "f165", ["M512 277.9c-.16 30.35-26.16 58.1-59.55 58.1H350.72C358 364.34 384 388.13 384 430.55 384 504 336 512 312 512c-20.18 0-29.48-39.29-33.93-57.79-5.21-21.67-10.59-44.07-25.39-58.91-32.47-32.52-49.51-74-89.12-113.11a12 12 0 0 1-3.56-8.52V59.9a12 12 0 0 1 11.78-12c15.83-.29 36.7-9.08 52.66-16.17C256.19 17.6 295.71 0 344 0h2.85c42.78 0 93.36.41 113.77 29.74 8.39 12.05 10.45 27 6.15 44.63 16.31 17 25.06 48.86 16.38 74.76 17.55 23.43 19.15 56.13 9.31 79.46l.11.12C504.45 240.65 512.08 260 512 277.9z", "M104 32H24A24 24 0 0 0 0 56v240a24 24 0 0 0 24 24h80a24 24 0 0 0 24-24V56a24 24 0 0 0-24-24zM64 280a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"]]
};
var faThumbsUp = {
  prefix: 'fad',
  iconName: 'thumbs-up',
  icon: [512, 512, [], "f164", ["M163.56 229.81c39.61-39.15 56.65-80.59 89.12-113.11 14.8-14.84 20.18-37.24 25.39-58.91C282.52 39.29 291.82 0 312 0c24 0 72 8 72 81.45 0 42.42-26 66.21-33.28 94.55h101.73c33.39 0 59.39 27.75 59.55 58.1.08 17.94-7.55 37.25-19.44 49.19l-.11.12c9.84 23.33 8.24 56-9.31 79.46 8.68 25.9-.07 57.71-16.38 74.76 4.3 17.6 2.24 32.58-6.15 44.63C440.2 511.59 389.62 512 346.84 512H344c-48.28 0-87.8-17.6-119.56-31.73-16-7.09-36.82-15.88-52.65-16.17a12 12 0 0 1-11.78-12V238.33a12 12 0 0 1 3.55-8.52z", "M104 224H24a24 24 0 0 0-24 24v240a24 24 0 0 0 24 24h80a24 24 0 0 0 24-24V248a24 24 0 0 0-24-24zM64 472a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"]]
};
var faTombstone = {
  prefix: 'fad',
  iconName: 'tombstone',
  icon: [512, 512, [], "f720", ["M448 192C448 86 362 0 256 0S64 86 64 192v224h384zm-96-8a16 16 0 0 1-16 16h-56v128a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16V200h-56a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h56v-48a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16v48h56a16 16 0 0 1 16 16z", "M496 448H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM176 200h56v128a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V200h56a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-56v-48a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v48h-56a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16z"]]
};
var faTombstoneAlt = {
  prefix: 'fad',
  iconName: 'tombstone-alt',
  icon: [512, 512, [], "f721", ["M448 192v224H64V192C64 86 150 0 256 0s192 86 192 192z", "M512 464v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h480a16 16 0 0 1 16 16z"]]
};
var faTrash = {
  prefix: 'fad',
  iconName: 'trash',
  icon: [448, 512, [], "f1f8", ["M53.2 467L32 96h384l-21.2 371a48 48 0 0 1-47.9 45H101.1a48 48 0 0 1-47.9-45z", "M0 80V48a16 16 0 0 1 16-16h120l9.4-18.7A23.72 23.72 0 0 1 166.8 0h114.3a24 24 0 0 1 21.5 13.3L312 32h120a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H16A16 16 0 0 1 0 80z"]]
};
var faTrashAlt = {
  prefix: 'fad',
  iconName: 'trash-alt',
  icon: [448, 512, [], "f2ed", ["M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96H32zm272-288a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0z", "M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM128 160a16 16 0 0 0-16 16v224a16 16 0 0 0 32 0V176a16 16 0 0 0-16-16zm96 0a16 16 0 0 0-16 16v224a16 16 0 0 0 32 0V176a16 16 0 0 0-16-16zm96 0a16 16 0 0 0-16 16v224a16 16 0 0 0 32 0V176a16 16 0 0 0-16-16z"]]
};
var faUser = {
  prefix: 'fad',
  iconName: 'user',
  icon: [448, 512, [], "f007", ["M352 128A128 128 0 1 1 224 0a128 128 0 0 1 128 128z", "M313.6 288h-16.7a174.1 174.1 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"]]
};
var _iconsCache = {
  faArrowAltDown: faArrowAltDown,
  faArrowAltUp: faArrowAltUp,
  faBus: faBus,
  faBusAlt: faBusAlt,
  faCar: faCar,
  faCarSide: faCarSide,
  faCircle: faCircle,
  faClock: faClock,
  faEdit: faEdit,
  faFileAlt: faFileAlt,
  faInfoCircle: faInfoCircle,
  faInfoSquare: faInfoSquare,
  faListAlt: faListAlt,
  faLongArrowRight: faLongArrowRight,
  faPrint: faPrint,
  faSack: faSack,
  faSlash: faSlash,
  faSpinner: faSpinner,
  faSpinnerThird: faSpinnerThird,
  faThumbsDown: faThumbsDown,
  faThumbsUp: faThumbsUp,
  faTombstone: faTombstone,
  faTombstoneAlt: faTombstoneAlt,
  faTrash: faTrash,
  faTrashAlt: faTrashAlt,
  faUser: faUser
};

export { _iconsCache as fad, prefix, faArrowAltDown, faArrowAltUp, faBus, faBusAlt, faCar, faCarSide, faCircle, faClock, faEdit, faFileAlt, faInfoCircle, faInfoSquare, faListAlt, faLongArrowRight, faPrint, faSack, faSlash, faSpinner, faSpinnerThird, faThumbsDown, faThumbsUp, faTombstone, faTombstoneAlt, faTrash, faTrashAlt, faUser };
